import React from "react"

import { useTranslation } from "react-i18next"

import Breadcrumbs from "../../../../components/Breadcrumbs"
import Intro from "../../../../components/Intro"
import Space from "../../../../components/Space"
import View from "../../../../components/View"

import "./style.sass"

const Dashboard = () => {
  const { t } = useTranslation()

  return (
    <View className="Dashboard">
      <Breadcrumbs
        depth={3}
        values={[
          t("desktop.analytics.title"),
          t("desktop.analytics.visitor_management.title"),
          t("desktop.analytics.visitor_management.dashboard.title"),
        ]}
      />

      <Intro>
        {t("desktop.analytics.visitor_management.dashboard.subtitle")}
      </Intro>

      <Space size={0.75} />
    </View>
  )
}

export default Dashboard
