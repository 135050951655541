import React from "react"

import { useTranslation } from "react-i18next"

import { DESKS_PATHS } from "./Desks/constants"
import { OVERVIEW_PATHS } from "./Overview/constants"
import { ROOMS_PATHS } from "./Rooms/constants"
import { VISITORS_PATHS } from "./Visitors/constants"

import AuxNav, { AuxButton } from "../../components/AuxNav"
import { NavMenu, NavSubItem } from "../../components/NavMenu"

import BadgeSVG from "../../assets/images/icons/BadgeOutline.svg"
import ChairSVG from "../../assets/images/icons/Chair.svg"
import RoomSVG from "../../assets/images/icons/Room.svg"
import SpeedometerSVG from "../../assets/images/icons/Speedometer.svg"

export const AnalyticsNav = () => {
  const { t } = useTranslation()

  return (
    <AuxNav className="Analytics">
      {/*
				Overview
			*/}
      <AuxButton to={OVERVIEW_PATHS.root}>
        <SpeedometerSVG />
        <span>{t("desktop.analytics.overview.title")}</span>
      </AuxButton>

      {/*
				Rooms
			*/}
      <NavMenu
        icon={<RoomSVG />}
        text={t("desktop.analytics.room_booking.title")}
        to={ROOMS_PATHS.root}
        activePath={ROOMS_PATHS.root}
      >
        <NavSubItem to={ROOMS_PATHS.meetings}>
          {t("desktop.analytics.room_booking.meetings.title")}
        </NavSubItem>

        <NavSubItem to={ROOMS_PATHS.people}>
          {t("desktop.analytics.room_booking.people.title")}
        </NavSubItem>

        <NavSubItem to={ROOMS_PATHS.rooms}>
          {t("desktop.analytics.room_booking.rooms.title")}
        </NavSubItem>
      </NavMenu>

      {/*
				Desks & Assets
			*/}
      <NavMenu
        icon={<ChairSVG />}
        text={t("desktop.analytics.desk_and_asset_booking.title")}
        to={DESKS_PATHS.root}
        activePath={DESKS_PATHS.root}
      >
        <NavSubItem to={DESKS_PATHS.people}>
          {t("desktop.analytics.desk_and_asset_booking.people.title")}
        </NavSubItem>

        <NavSubItem to={DESKS_PATHS.desks}>
          {t("desktop.analytics.desk_and_asset_booking.desks.title")}
        </NavSubItem>

        <NavSubItem to={DESKS_PATHS.assets}>
          {t("desktop.analytics.desk_and_asset_booking.assets.title")}
        </NavSubItem>
      </NavMenu>

      {/*
				Visitors
			*/}
      <NavMenu
        icon={<BadgeSVG />}
        text={t("desktop.analytics.visitor_management.title")}
        to={VISITORS_PATHS.root}
        activePath={VISITORS_PATHS.root}
      >
        <NavSubItem to={VISITORS_PATHS.dashboard}>
          {t("desktop.analytics.visitor_management.dashboard.title")}
        </NavSubItem>

        <NavSubItem to={VISITORS_PATHS.dailyOverview}>
          {t("desktop.analytics.visitor_management.daily_overview.title")}
        </NavSubItem>
      </NavMenu>
    </AuxNav>
  )
}
