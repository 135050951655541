import React from "react"

import { Redirect, Route, Switch } from "react-router-dom"

import { FEATURE_FLAGS } from "../../constants"
import { useCheckForFeatureFlag } from "../../hooks/useCheckForFeatureFlag"
import Desks from "../Analytics/Desks"
import Overview from "../Analytics/Overview"
import Rooms from "../Analytics/Rooms"
import Visitors from "../Analytics/Visitors"
import NotFound from "../NotFound"
import { AnalyticsNav } from "./AnalyticsNav"
import { DESKS_PATHS } from "./Desks/constants"
import { OVERVIEW_PATHS } from "./Overview/constants"
import { ROOMS_PATHS } from "./Rooms/constants"
import { VISITORS_PATHS } from "./Visitors/constants"

import { useAppSelector } from "../../redux/reducers"
import { selectUser } from "../../redux/user/selectors"
import { isPortalAdmin } from "../../redux/user/utils"

import ProtectedRoute from "../../components/ProtectedRoute"
import RouteView from "../../components/RouteView"
import Screen from "../../components/Screen"

function Analytics() {
  const { entry: currentUser } = useAppSelector(selectUser)

  const isAdmin = isPortalAdmin(currentUser)

  const areEmbeddedAnalyticsEnabled = useCheckForFeatureFlag(
    FEATURE_FLAGS.EMBEDDED_ANALYTICS,
  )

  const canAccessAnalytics = isAdmin && areEmbeddedAnalyticsEnabled

  if (!canAccessAnalytics) {
    return (
      <RouteView className="Analytics">
        <NotFound onlyView />
      </RouteView>
    )
  }

  return (
    <RouteView className="Analytics">
      <Screen sidebar={<AnalyticsNav />}>
        <Switch>
          <ProtectedRoute path={OVERVIEW_PATHS.root} component={Overview} />

          <ProtectedRoute path={ROOMS_PATHS.root} component={Rooms} />

          <ProtectedRoute path={DESKS_PATHS.root} component={Desks} />

          <ProtectedRoute path={VISITORS_PATHS.root} component={Visitors} />

          <Redirect to={OVERVIEW_PATHS.root} />

          <Route render={() => <NotFound onlyView />} />
        </Switch>
      </Screen>
    </RouteView>
  )
}

export default Analytics
