import dayjs from "dayjs"
import { TFunction } from "i18next"

import { NotificationCardProps } from ".."
import { SensitivityOptions } from "../types"
import { EVENT_ICON, EVENT_SENSITIVITY } from "./constants"
import Date from "./Content/Date"
import Location from "./Content/Location"
import Time from "./Content/Time"

import {
  INVITE_CANCELLED_HOST,
  INVITE_CHECKED_IN_HOST,
  INVITE_CREATED_HOST,
  INVITE_UPDATED_HOST,
  Notification,
  NotificationType,
  VISITOR_EVENTS,
} from "../../../../redux/api/notifications/types"

import "./style.sass"

type ActionGeneratorUtility = {
  push: (pathname: string) => void
  t: TFunction<"translation", undefined>
}

type NotificationCardGenerator = {
  notification: Notification
  utilities: ActionGeneratorUtility
}

type GeneratedProps = Omit<
  NotificationCardProps,
  "listId" | "discardable"
> | null

const generateActions = (
  type: NotificationType,
  { push, t }: Partial<ActionGeneratorUtility>,
) => {
  switch (type) {
    default:
      return null
  }
}

const getNotificationSensitivity = (
  type: NotificationType,
  seen_at: Notification["seen_at"],
) => {
  if (seen_at !== null) {
    return SensitivityOptions.NONE
  }

  return EVENT_SENSITIVITY[type] || SensitivityOptions.NORMAL
}

const generateNotificationCardProps = ({
  notification,
  utilities,
}: NotificationCardGenerator): GeneratedProps => {
  const { id, type, seen_at, message, created_at } = notification
  const { push, t } = utilities

  const PREPOPULATED_VALUES = {
    id,
    icon: EVENT_ICON[type],
    actions: generateActions(type, {
      push,
    }),
    sensitivity: getNotificationSensitivity(type, seen_at),
    dismissable: seen_at === null,
    subtitle: dayjs(created_at).format("MMM DD, YYYY · HH:mm"),
  }

  switch (type) {
    case VISITOR_EVENTS.INVITE_CREATED_HOST: {
      const { visitor, start, end, building, floor, tz } =
        message as INVITE_CREATED_HOST
      return {
        ...PREPOPULATED_VALUES,
        title: t(`desktop.settings.profile.notifications.cards.${type}.title`, {
          name: visitor.full_name,
        }),
        content: (
          <>
            <Date dateTime={start} />
            <Time start={start} end={end} tz={tz} />
            <Location building={building} floor={floor} tz={tz} />
          </>
        ),
      }
    }
    case VISITOR_EVENTS.INVITE_UPDATED_HOST: {
      const { visitor, start, end, building, floor, tz } =
        message as INVITE_UPDATED_HOST
      return {
        ...PREPOPULATED_VALUES,
        title: t(`desktop.settings.profile.notifications.cards.${type}.title`, {
          name: visitor.full_name,
        }),
        content: (
          <>
            <Date dateTime={start} />
            <Time start={start} end={end} tz={tz} />
            <Location building={building} floor={floor} tz={tz} />
          </>
        ),
      }
    }
    case VISITOR_EVENTS.INVITE_CHECKED_IN_HOST: {
      const { visitor, start, end, building, floor, tz } =
        message as INVITE_CHECKED_IN_HOST
      return {
        ...PREPOPULATED_VALUES,
        title: t(`desktop.settings.profile.notifications.cards.${type}.title`, {
          name: visitor.full_name,
        }),
        content: (
          <>
            <Date dateTime={start} />
            <Time start={start} end={end} tz={tz} />
            <Location building={building} floor={floor} tz={tz} />
          </>
        ),
      }
    }
    case VISITOR_EVENTS.INVITE_CANCELLED_HOST: {
      const { visitor, start, end, building, floor, tz } =
        message as INVITE_CANCELLED_HOST
      return {
        ...PREPOPULATED_VALUES,
        title: t(`desktop.settings.profile.notifications.cards.${type}.title`, {
          name: visitor.full_name,
        }),
        content: (
          <>
            <div className="description-row">
              {t(
                `desktop.settings.profile.notifications.cards.${type}.subtitle`,
                {
                  name: visitor.full_name,
                },
              )}
            </div>
            <Date dateTime={start} />
            <Time start={start} end={end} tz={tz} />
            <Location building={building} floor={floor} tz={tz} />
            <div className="description-row">
              {t(
                `desktop.settings.profile.notifications.cards.${type}.subtitle_2`,
              )}
            </div>
          </>
        ),
      }
    }
    default: {
      return null
    }
  }
}

export default generateNotificationCardProps
