import React from "react"

import { useTranslation } from "react-i18next"

import Breadcrumbs from "../../../../components/Breadcrumbs"
import Intro from "../../../../components/Intro"
import Space from "../../../../components/Space"
import View from "../../../../components/View"

import "./style.sass"

const DesksPage = () => {
  const { t } = useTranslation()

  return (
    <View className="Desks">
      <Breadcrumbs
        depth={3}
        values={[
          t("desktop.analytics.title"),
          t("desktop.analytics.desk_and_asset_booking.title"),
          t("desktop.analytics.desk_and_asset_booking.desks.title"),
        ]}
      />

      <Intro>
        {t("desktop.analytics.desk_and_asset_booking.desks.subtitle")}
      </Intro>

      <Space size={0.75} />
    </View>
  )
}

export default DesksPage
