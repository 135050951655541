export const ROOMS_ROOT_PATH = "/analytics/rooms"
export const ROOMS_MEETINGS_PATHNAME = `${ROOMS_ROOT_PATH}/meetings`
export const ROOMS_PEOPLE_PATHNAME = `${ROOMS_ROOT_PATH}/people`
export const ROOMS_ROOMS_PATHNAME = `${ROOMS_ROOT_PATH}/rooms`

export const ROOMS_PATHS = {
  root: ROOMS_ROOT_PATH,
  meetings: ROOMS_MEETINGS_PATHNAME,
  people: ROOMS_PEOPLE_PATHNAME,
  rooms: ROOMS_ROOMS_PATHNAME,
} as const
