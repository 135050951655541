import React from "react"

import { useTranslation } from "react-i18next"

import Breadcrumbs from "../../../../components/Breadcrumbs"
import Intro from "../../../../components/Intro"
import Space from "../../../../components/Space"
import View from "../../../../components/View"

const OverviewPage = () => {
  const { t } = useTranslation()

  return (
    <View className="Overview">
      <Breadcrumbs depth={1} values={[t("desktop.analytics.overview.title")]} />

      <Intro>{t("desktop.analytics.overview.subtitle")}</Intro>

      <Space size={0.75} />
    </View>
  )
}

export default OverviewPage
