import { useState } from "react"

import cn from "classnames"
import { motion, useAnimation, useDragControls } from "framer-motion"

import { Children } from "../../../types/sharedTypes"
import { NotificationsVariant } from "../NotificationList"
import {
  animationDelays,
  animationVariants,
  mobileSwipeOffset,
} from "./constants"
import { Sensitivity, SensitivityOptions } from "./types"

import {
  useMarkNotificationsAsReadAndDiscardMutation,
  useMarkNotificationsAsReadMutation,
} from "../../../redux/api/notifications"
import { selectIsMobile } from "../../../redux/app/selectors"
import { useAppSelector } from "../../../redux/reducers"

import CheckCircleSVG from "../../../assets/images/icons/NotificationCheckCircle.svg"
import CheckCircleCompleteSVG from "../../../assets/images/icons/NotificationCheckCirclePurple.svg"

import "./style.sass"

export type NotificationCardProps = {
  id: string
  listId: string
  title: string
  content: Children
  icon: Children
  subtitle?: string
  actions?: Children
  sensitivity?: Sensitivity
  dismissable?: boolean
  discardable?: boolean
  variant?: NotificationsVariant
}

const NotificationCard = ({
  id,
  title,
  listId,
  subtitle,
  content,
  icon,
  actions,
  sensitivity = SensitivityOptions.NONE,
  dismissable,
  discardable,
}: NotificationCardProps) => {
  const swipeDragControls = useDragControls()
  const swipeAnimationControls = useAnimation()
  const cardControls = useAnimation()

  const isMobile = useAppSelector(selectIsMobile)

  const [markNotificationAsRead] = useMarkNotificationsAsReadMutation()
  const [markNotificationAsReadAndDiscard] =
    useMarkNotificationsAsReadAndDiscardMutation()

  const [isDismissed, setIsDismissed] = useState(false)

  // Commented out until the moment of implementation when a notification is marked as read it should be processed in the same way as marking all as read
  // const toastContainerId =
  //   variant === "list" ? TOAST_LIST_CONTAINER_ID : TOAST_POPUP_CONTAINER_ID

  const onDismissActions = async () => {
    if (discardable) {
      await cardControls.start("hidden")
      await markNotificationAsReadAndDiscard({
        ids: [id],
        listId,
      })

      // Commented out until the moment of implementation when a notification is marked as read it should be processed in the same way as marking all as read
      // if (isRejected(response)) {
      //   errorToast(response.error.message)
      //
      //   return
      // }
      // if (isMobile) return
      //
      // infoToast(
      //   t(
      //     "desktop.settings.profile.notifications.toasts.mark_as_read_success",
      //     { title: id },
      //   ),
      //   {
      //     hideProgressBar: true,
      //     containerId: toastContainerId,
      //   },
      // )
    } else {
      setTimeout(async () => {
        await markNotificationAsRead({ ids: [id], listId })

        // Commented out until the moment of implementation when a notification is marked as read it should be processed in the same way as marking all as read
        // if (isRejected(response)) {
        //   errorToast(response.error.message)
        //
        //   return
        // }
        // if (isMobile) return
        //
        // infoToast(
        //   t(
        //     "desktop.settings.profile.notifications.toasts.mark_as_read_success",
        //     { title: id },
        //   ),
        //   {
        //     hideProgressBar: true,
        //     containerId: toastContainerId,
        //   },
        // )
      }, animationDelays.checkmark.ms * 3)
    }
  }

  const handleDismiss = async () => {
    setIsDismissed(true)
    setTimeout(() => {
      onDismissActions()
    }, animationDelays.checkmark.ms * 1.25)
  }

  const handleSwipeDismiss = async () => {
    setIsDismissed(true)
    setTimeout(async () => {
      await markNotificationAsReadAndDiscard({
        ids: [id],
        listId,
      })
    }, animationDelays.checkmark.ms)
  }

  const parentCn = cn("NotificationCard", {
    [`sensitivity-${sensitivity}`]: sensitivity,
  })

  const isDismissable = dismissable && !isMobile

  const dismissActions = isDismissed ? (
    <motion.div
      variants={animationVariants}
      initial="opacityHidden"
      animate="opacityVisible"
      className="dismiss"
    >
      <CheckCircleCompleteSVG className="dismiss" />
    </motion.div>
  ) : (
    <CheckCircleSVG className="dismiss" onClick={handleDismiss} />
  )

  const swipeDismissActions = isDismissed ? (
    <motion.div
      variants={animationVariants}
      initial="opacityHidden"
      animate="opacityVisible"
      className="swipe-indicator"
    >
      <CheckCircleCompleteSVG className="dismiss" />
    </motion.div>
  ) : (
    <CheckCircleSVG className="dismiss" />
  )

  const notificationCardContent = (
    <div className={parentCn}>
      <div className="information">
        <div className="icon">{icon}</div>
        <div className="title">{title}</div>
        {subtitle && <div className="subtitle">{subtitle}</div>}
        <div className="description">{content}</div>
        {actions && <div className="actions">{actions}</div>}
        {isDismissable ? dismissActions : null}
      </div>
    </div>
  )

  return isMobile ? (
    <motion.div
      transition={{ duration: animationDelays.card.s }}
      layout
      className="swipe-parent"
    >
      <motion.div
        variants={animationVariants}
        initial="opacityHidden"
        animate="opacityVisible"
        transition={{ delay: animationDelays.swipeIndicator.s }}
        layout
        className="swipe-indicator"
      >
        {swipeDismissActions}
      </motion.div>
      <motion.div
        dragControls={swipeDragControls}
        animate={swipeAnimationControls}
        variants={animationVariants}
        initial="visible"
        className="swipe-content"
        layout
        dragConstraints={{ left: mobileSwipeOffset, right: 0 }}
        dragElastic={false}
        drag="x"
        onDragEnd={(_event, info) => {
          if (info.offset.x > mobileSwipeOffset) {
            swipeAnimationControls.start({ x: 0 })
            return
          }
          handleSwipeDismiss()
        }}
      >
        {notificationCardContent}
      </motion.div>
    </motion.div>
  ) : (
    <motion.div
      layout
      variants={animationVariants}
      initial={false}
      animate={cardControls}
      transition={{ duration: animationDelays.card.s }}
    >
      {notificationCardContent}
    </motion.div>
  )
}

export default NotificationCard
