export const DESKS_ROOT_PATH = "/analytics/desks"
export const DESKS_PEOPLE_PATHNAME = `${DESKS_ROOT_PATH}/people`
export const DESKS_DESKS_PATHNAME = `${DESKS_ROOT_PATH}/desks`
export const DESKS_ASSETS_PATHNAME = `${DESKS_ROOT_PATH}/assets`

export const DESKS_PATHS = {
  root: DESKS_ROOT_PATH,
  people: DESKS_PEOPLE_PATHNAME,
  desks: DESKS_DESKS_PATHNAME,
  assets: DESKS_ASSETS_PATHNAME,
} as const
