import React from "react"

import { useTranslation } from "react-i18next"

import Breadcrumbs from "../../../../components/Breadcrumbs"
import Intro from "../../../../components/Intro"
import Space from "../../../../components/Space"
import View from "../../../../components/View"

import "./style.sass"

const RoomsPage = () => {
  const { t } = useTranslation()

  return (
    <View className="Rooms">
      <Breadcrumbs
        depth={3}
        values={[
          t("desktop.analytics.title"),
          t("desktop.analytics.room_booking.title"),
          t("desktop.analytics.room_booking.rooms.title"),
        ]}
      />

      <Intro>{t("desktop.analytics.room_booking.rooms.subtitle")}</Intro>

      <Space size={0.75} />
    </View>
  )
}

export default RoomsPage
