import React from "react"

import { Switch as RouteSwitch } from "react-router-dom"

import { OVERVIEW_PATHS } from "./constants"
import OverviewPage from "./Overview"

import ProtectedRoute from "../../../components/ProtectedRoute"

const Overview = () => {
  return (
    <RouteSwitch>
      <ProtectedRoute
        exact
        path={OVERVIEW_PATHS.root}
        component={OverviewPage}
      />
    </RouteSwitch>
  )
}

export default Overview
