import React, { useState } from "react"

import { t } from "i18next"

import { FETCH_FOR_COUNT, FETCH_UNREAD } from "../../../../../constants"
import { useToast } from "../../../../../hooks/useToast"
import { MultiOptionType } from "../../../../../types/sharedTypes"
import { ROLE_EVENTS } from "../constants"

import {
  useFetchNotificationsQuery,
  useMarkNotificationsAsReadAndDiscardMutation,
  useMarkNotificationsAsReadMutation,
} from "../../../../../redux/api/notifications"
import { isRejected } from "../../../../../redux/api/types"
import { useAppSelector } from "../../../../../redux/reducers"
import { selectUser } from "../../../../../redux/user/selectors"
import { isOfficeManager, isPortalAdmin } from "../../../../../redux/user/utils"

import Button from "../../../../../components/advanced/Button"
import { SUPPORTED_NOTIFICATION_TYPES } from "../../../../../components/advanced/NotificationCard/NotificationCardGenerator/constants"
import { SupportedEventType } from "../../../../../components/advanced/NotificationCard/NotificationCardGenerator/types"
import NotificationList from "../../../../../components/advanced/NotificationList"
import DropdownMulti from "../../../../../components/basic/DropdownMulti"
import Switch from "../../../../../components/basic/Switch"
import Breadcrumbs from "../../../../../components/Breadcrumbs"
import Filters from "../../../../../components/Filter/Filters"
import FilterSpace from "../../../../../components/Filter/FilterSpace"
import { FilterSpecialValues } from "../../../../../components/Filter/types"
import Intro from "../../../../../components/Intro"
import Space from "../../../../../components/Space"
import View from "../../../../../components/View"

import CheckAllSVG from "../../../../../assets/images/icons/CheckAll.svg"

import "./styles.sass"

export const TOAST_LIST_CONTAINER_ID = "notification-list-toast-container"

const Notifications = () => {
  const { errorToast, infoToast } = useToast()

  const { entry: user } = useAppSelector(selectUser)

  const [markAllNotificationsAsRead] = useMarkNotificationsAsReadMutation()
  const [markAllNotificationsAsReadAndDiscard] =
    useMarkNotificationsAsReadAndDiscardMutation()

  const { data: { count: notificationCount = 0 } = {} } =
    useFetchNotificationsQuery({ ...FETCH_FOR_COUNT, ...FETCH_UNREAD })

  const isAdmin = isPortalAdmin(user)
  const isManager = !isAdmin && isOfficeManager(user)
  const isUser = !isAdmin && !isManager

  const userRoleIndex = [isAdmin, isManager, isUser].findIndex((v) => v)

  const [listId, setListId] = useState<string | null>(null)

  const [typesFilter, setTypesFilter] = useState<SupportedEventType[]>([])

  const [onlyShowUnreadFilter, setOnlyShowUnreadFilter] =
    useState<boolean>(false)

  const handleOnlyShowUnreadFilter = () =>
    setOnlyShowUnreadFilter((prev) => !prev)

  const handleListIdGenerated = (id: string) => setListId(id)

  const handleMarkAllAsReadClick = async () => {
    if (!listId) {
      return
    }

    const response = await markAllNotificationsAsRead({
      ids: [],
      listId,
    })

    if (isRejected(response)) {
      errorToast(response.error.message)

      return
    }

    infoToast(
      t(
        "desktop.settings.profile.notifications.toasts.mark_all_as_read_success",
      ),
      {
        hideProgressBar: true,
        containerId: TOAST_LIST_CONTAINER_ID,
      },
    )
  }

  const handleMarkAllAsReadAndDiscardClick = async () => {
    if (!listId) {
      return
    }

    const response = await markAllNotificationsAsReadAndDiscard({
      ids: [],
      listId,
    })

    if (isRejected(response)) {
      errorToast(response.error.message)

      return
    }

    infoToast(
      t(
        "desktop.settings.profile.notifications.toasts.mark_all_as_read_success",
      ),
      {
        hideProgressBar: true,
        containerId: TOAST_LIST_CONTAINER_ID,
      },
    )
  }

  const notificationTypeOptions = getSupportedNotificationTypesOptions(
    ROLE_EVENTS,
    userRoleIndex,
  )

  const count = notificationTypeOptions.length

  notificationTypeOptions.unshift({
    label: (
      <>
        {t("desktop.settings.profile.notifications.filters.all_types_label")}{" "}
        <span className="count">&middot; {count}</span>
      </>
    ),
    value: FilterSpecialValues.EMPTY as unknown as SupportedEventType,
    isSingleSelect: true,
  })

  return (
    <View className="Notifications">
      <Breadcrumbs
        depth={2}
        values={[
          t("desktop.settings.profile.general.account"),
          t("desktop.settings.profile.notifications.notifications"),
        ]}
      />

      <Intro isConstrained>
        {t("desktop.settings.profile.notifications.notifications_intro")}
      </Intro>

      <Space size={0.75} />

      <Filters>
        <DropdownMulti
          className="types-filters"
          options={notificationTypeOptions}
          values={typesFilter}
          onChange={setTypesFilter}
          optionsName={t("general.types").toLowerCase()}
          defaultLabel={t(
            "desktop.settings.profile.notifications.filters.all_types_label",
          )}
        />

        <Switch
          className="only-show-unread-filter"
          label={t(
            "desktop.settings.profile.notifications.filters.only_show_unread_label",
          )}
          value={onlyShowUnreadFilter}
          onChange={handleOnlyShowUnreadFilter}
        />

        <FilterSpace />

        <Button
          className="mark-all-as-read"
          variant="link"
          icon={<CheckAllSVG />}
          onClick={
            onlyShowUnreadFilter
              ? handleMarkAllAsReadAndDiscardClick
              : handleMarkAllAsReadClick
          }
          isDisabled={notificationCount === 0}
        >
          {t("desktop.settings.profile.notifications.actions.mark_all_as_read")}
        </Button>
      </Filters>

      <Space size={0.75} />

      <NotificationList
        limit={25}
        type={typesFilter}
        onListIdGenerated={handleListIdGenerated}
        onlyShowUnread={onlyShowUnreadFilter}
        containerHeight="75vh"
        variant="list"
      />
    </View>
  )
}

const getSupportedNotificationTypesOptions = (
  types: typeof ROLE_EVENTS,
  userRoleIndex: number,
): MultiOptionType<SupportedEventType>[] => {
  const eventList: MultiOptionType<SupportedEventType>[] = []

  const roleKeys = Object.keys(types)
  const role = roleKeys[userRoleIndex]

  if (!role) {
    console.warn("Invalid role index provided")
    return []
  }

  const roleEvents = types[role as keyof typeof types]

  Object.keys(roleEvents).forEach((eventCategory) => {
    const categoryEvents = roleEvents[eventCategory as keyof typeof roleEvents]

    categoryEvents.forEach((event) => {
      if (SUPPORTED_NOTIFICATION_TYPES.includes(event as SupportedEventType)) {
        eventList.push({
          label: t(
            `desktop.settings.profile.notifications.filters.types.${event}`,
          ),
          value: event as SupportedEventType,
          isSingleSelect: false,
        })
      }
    })
  })

  return eventList
}

export default Notifications
